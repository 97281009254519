import React, { useEffect } from "react";

import { TooltipPortal } from "@radix-ui/react-tooltip";
import { ArrowLeft, CheckIcon, ListFilter } from "lucide-react";

import { FilterSheet } from "~/components/Reports/ListToList/FilterSheet";
import FilterTooltip from "~/components/Reports/ListToList/FilterTooltip";
import { Switch } from "~/components/ui/switch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Badge } from "~/components/ui-rework/badge";
import { Button } from "~/components/ui-rework/button";
import { Label } from "~/components/ui-rework/label";
import { Sheet, SheetTrigger } from "~/components/ui-rework/sheet";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui-rework/tooltip";
import { cn } from "~/lib/utils";
import { IListManagerList } from "~/models/ListManager";

type ListToListTableProps = {
  relationshipData: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  resetLists: () => void;
};

export const ListToListTable = ({
  relationshipData,
  setLoading,
  resetLists,
}: ListToListTableProps) => {
  const fromList = relationshipData.from_list;
  const toList = relationshipData.to_list;
  const [listLeft, setListLeft] = React.useState<IListManagerList | null>(null);
  const [listTop, setListTop] = React.useState<IListManagerList | null>(null);
  const [listsSet, setListsSet] = React.useState(false);
  const [codeOnly, setCodeOnly] = React.useState(false);
  const [filterTopList, setFilterTopList] = React.useState<number[]>([]);
  const [filterLeftList, setFilterLeftList] = React.useState<number[]>([]);
  const [adjustWidth, setAdjustWidth] = React.useState(false);
  const totalColumns = getTotalColumns();

  useEffect(() => {
    if (!listsSet) {
      if (
        relationshipData.from_listitem_count >
        relationshipData.to_listitem_count
      ) {
        setListLeft(fromList);
        setListTop(toList);
      } else {
        setListLeft(toList);
        setListTop(fromList);
      }
      setLoading(false);
      setListsSet(true);
    }
  }, [relationshipData, fromList, toList, listsSet, setLoading]);

  useEffect(() => {
    if (adjustWidth || adjustDivWidth) {
      adjustDivWidth();
      window.addEventListener("resize", adjustDivWidth);
      return () => {
        window.removeEventListener("resize", adjustDivWidth);
      };
    }
  }, [adjustWidth, adjustDivWidth, codeOnly]);

  function adjustDivWidth() {
    setAdjustWidth(false);
    const table = document.querySelector("#table") as HTMLElement; // Type assertion
    const targetDiv = document.querySelector("#fixed-div") as HTMLElement;
    if (table && targetDiv) {
      targetDiv.style.minWidth = `${table.offsetWidth}px`;
    }
  }

  function getTotalColumns() {
    let columns = 0;
    for (const item of listTop?.items?.map((item: any) => item) || []) {
      if (filterTopList.length > 0 && !filterTopList.includes(item.id)) {
        continue;
      }
      columns += item.items?.length + 1;
    }
    return columns;
  }

  return (
    <>
      <div
        id="fixed-div"
        className="flex p-2 sticky top-0 bg-white z-20 justify-between h-[60px]"
      >
        <div className="flex items-center max-w-fit fixed space-x-2">
          <Button
            size="sm"
            variant="outline"
            onClick={() => {
              setListLeft(null);
              setListTop(null);
              resetLists();
            }}
            className="light"
          >
            <ArrowLeft className="p-0.5" size={24} />
            <span className="font-normal text-sm">Select new lists</span>
          </Button>
          <Sheet
            onOpenChange={(open) => {
              if (!open) {
                setAdjustWidth(true);
              }
            }}
          >
            <SheetTrigger asChild>
              <div className="relative">
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    {filterTopList.length > 0 || filterLeftList.length > 0 ? (
                      <TooltipTrigger asChild>
                        <Button size="sm" variant="primary" className="light">
                          <ListFilter className="p-0.5" size={24} />
                          <span className="font-normal text-sm mx-2">
                            Filter
                          </span>
                          {(filterTopList.length > 0 ||
                            filterLeftList.length > 0) && (
                            <Badge variant="default">
                              {filterTopList.length + filterLeftList.length}
                            </Badge>
                          )}
                        </Button>
                      </TooltipTrigger>
                    ) : (
                      <Button size="sm" variant="primary" className="light">
                        <ListFilter className="p-0.5" size={24} />
                        <span className="font-normal text-sm mx-2">Filter</span>
                      </Button>
                    )}
                    <TooltipPortal>
                      <TooltipContent
                        side="bottom"
                        align="start"
                        className="border border-ocean-70 shadow-two"
                      >
                        <FilterTooltip
                          filterTopList={filterTopList}
                          filterLeftList={filterLeftList}
                          listTop={listTop}
                          listLeft={listLeft}
                        />
                      </TooltipContent>
                    </TooltipPortal>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </SheetTrigger>
            <FilterSheet
              setFilterTopList={setFilterTopList}
              setFilterLeftList={setFilterLeftList}
              listLeft={listLeft}
              listTop={listTop}
            />
          </Sheet>
        </div>
        <div className="flex items-center space-x-2 max-w-fit fixed right-[40px] mt-2.5">
          <Switch
            id="code-only"
            onClick={() => setCodeOnly(!codeOnly)}
            className="data-[state=checked]:bg-idesign-navy-100"
          />
          <Label className="m-0 cursor-pointer text-nowrap" htmlFor="code-only">
            Code only
          </Label>
        </div>
      </div>
      {listsSet && listTop && listLeft && (
        <Table
          id="table"
          sticky
          className="border-separate border-spacing-0 table-fixed"
        >
          <colgroup>
            <col
              key={`${listTop.name}-${listTop.created_at}`}
              style={{ width: "250px" }}
            />
            {listTop.items?.map((item: any, itemIndex: number) => {
              if (
                filterTopList.length > 0 &&
                !filterTopList.includes(item.id)
              ) {
                return null;
              }
              return (
                <React.Fragment key={`item-fragment-${item.id}-${itemIndex}`}>
                  <col
                    key={`item-col-${item.id}-${itemIndex}`}
                    style={{ width: codeOnly ? "100px" : "250px" }}
                  />
                  {item.items.map((child: any, childIndex: number) => (
                    <col
                      key={`child-col-${child.id}-${childIndex}`}
                      style={{ width: codeOnly ? "100px" : "250px" }}
                    />
                  ))}
                </React.Fragment>
              );
            })}
          </colgroup>
          <TableHeader className="sticky top-[60px] bg-white z-10">
            <TableRow key={`row-${listTop.created_at}`} className="border">
              <TableHead
                colSpan={1}
                key={`head-${listTop.created_at}`}
                className={cn(
                  "bg-white sticky left-0 border border-idesign-navy-20",
                  {
                    "font-bold": relationshipData.item_mappings.length === 0,
                  },
                )}
              >
                {relationshipData.item_mappings.length === 0
                  ? "No mappings found"
                  : null}
              </TableHead>
              <TableHead
                colSpan={totalColumns}
                key={`head-span-${listTop.created_at}`}
                className=" bg-white items-center px-0 border border-idesign-navy-20"
              >
                <span className="sticky left-[250px] px-3">{listTop.name}</span>
              </TableHead>
            </TableRow>
            <TableRow key={`row-${listLeft.name}`}>
              <TableHead
                key={`head-${listLeft.name}`}
                className={cn(
                  "border border-idesign-navy-20 p-2 align-bottom sticky left-0 bg-white",
                  { "text-right": codeOnly },
                )}
              >
                {listLeft.name}
              </TableHead>
              {listTop.items?.map((item: any, itemIndex: number) => {
                // FOR LIST FILTERING
                if (
                  filterTopList.length > 0 &&
                  !filterTopList.includes(item.id)
                ) {
                  return null;
                }
                return (
                  <React.Fragment key={`item-fragment-${item.id}-${itemIndex}`}>
                    <TableHead
                      key={`item-head-${item.id}-${itemIndex}`}
                      className={cn(
                        "border border-idesign-navy-20 p-2 text-default",
                        {
                          "font-bold": item.parent_id === 0,
                          "bg-idesign-navy-10": item.parent_id === 0,
                          "text-center": codeOnly,
                        },
                      )}
                    >
                      {codeOnly ? item.code : item.display_name}
                    </TableHead>
                    {item.items?.map((children: any, childIndex: number) => (
                      <TableHead
                        key={`child-head-${children.id}-${childIndex}`}
                        className={cn(
                          "border border-idesign-navy-20 p-2 text-default",
                          {
                            "font-bold": children.parent_id === 0,
                            "bg-idesign-navy-10": children.parent_id === 0,
                            "text-center": codeOnly,
                          },
                        )}
                      >
                        {codeOnly ? children.code : children.display_name}
                      </TableHead>
                    ))}
                  </React.Fragment>
                );
              })}
            </TableRow>
          </TableHeader>
          <TableBody>
            {listLeft.items?.map((leftItem: any, leftItemIndex: number) => {
              // FOR LIST FILTERING
              if (
                filterLeftList.length > 0 &&
                !filterLeftList.includes(leftItem.id)
              ) {
                return null;
              }
              return (
                <React.Fragment
                  key={`left-item-fragment-${leftItem.id}-${leftItemIndex}`}
                >
                  <TableRow
                    key={`left-item-row-${leftItem.id}-${leftItemIndex}`}
                  >
                    <TableCell
                      key={`left-item-cell-${leftItem.id}-${leftItemIndex}`}
                      className={cn(
                        "border border-idesign-navy-20 p-2 sticky left-0",
                        {
                          "font-bold": leftItem.parent_id === 0,
                          "bg-idesign-navy-10": leftItem.parent_id === 0,
                          "bg-white": leftItem.parent_id !== 0,
                          "text-right": codeOnly,
                        },
                      )}
                    >
                      {codeOnly ? leftItem.code : leftItem.display_name}
                    </TableCell>
                    {listTop.items?.map(
                      (topItem: any, topItemIndex: number) => {
                        // FOR LIST FILTERING
                        if (
                          filterTopList.length > 0 &&
                          !filterTopList.includes(topItem.id)
                        ) {
                          return null;
                        }
                        return (
                          <React.Fragment
                            key={`top-item-fragment-${topItem.id}-${topItemIndex}`}
                          >
                            <TableCell
                              key={`top-item-cell-${topItem.id}-${topItemIndex}`}
                              className={cn(
                                "border border-idesign-navy-20 p-2 align-middle",
                                {
                                  "bg-idesign-navy-10":
                                    topItem.parent_id === 0 ||
                                    leftItem.parent_id === 0,
                                },
                              )}
                            >
                              {relationshipData.item_mappings.find(
                                (mapping: any) =>
                                  (mapping.from_listitem_id === topItem.id ||
                                    mapping.from_listitem_id === leftItem.id) &&
                                  (mapping.to_listitem_id === topItem.id ||
                                    mapping.to_listitem_id === leftItem.id),
                              ) ? (
                                <CheckIcon className="w-full" size={24} />
                              ) : null}
                            </TableCell>
                            {topItem.items.map(
                              (child: any, childIndex: number) => (
                                <TableCell
                                  key={`top-child-cell-${child.id}-${childIndex}`}
                                  className={cn(
                                    " w-full border border-idesign-navy-20 p-2 align-middle",
                                    {
                                      "bg-idesign-navy-10":
                                        child.parent_id === 0 ||
                                        leftItem.parent_id === 0,
                                    },
                                  )}
                                >
                                  {relationshipData.item_mappings.find(
                                    (mapping: any) =>
                                      (mapping.from_listitem_id === child.id ||
                                        mapping.from_listitem_id ===
                                          leftItem.id) &&
                                      (mapping.to_listitem_id === child.id ||
                                        mapping.to_listitem_id === leftItem.id),
                                  ) ? (
                                    <CheckIcon className="w-full" size={24} />
                                  ) : null}
                                </TableCell>
                              ),
                            )}
                          </React.Fragment>
                        );
                      },
                    )}
                  </TableRow>

                  {leftItem.items.map((child: any, childIndex: number) => (
                    <TableRow key={`left-child-row-${child.id}-${childIndex}`}>
                      <TableCell
                        key={`left-child-cell-${child.id}-${childIndex}`}
                        className={cn(
                          "border border-idesign-navy-20 p-2 sticky left-0 pl-6",
                          {
                            "font-bold": child.parent_id === 0,
                            "bg-idesign-navy-10": child.parent_id === 0,
                            "bg-white": child.parent_id !== 0,
                            "text-right": codeOnly,
                          },
                        )}
                      >
                        {codeOnly ? child.code : child.display_name}
                      </TableCell>
                      {listTop.items?.map(
                        (topItem: any, topItemIndex: number) => {
                          // FOR LIST FILTERING
                          if (
                            filterTopList.length > 0 &&
                            !filterTopList.includes(topItem.id)
                          ) {
                            return null;
                          }
                          return (
                            <React.Fragment
                              key={`top-item-fragment-${topItem.id}-${topItemIndex}`}
                            >
                              <TableCell
                                key={`top-item-cell-${topItem.id}-${topItemIndex}`}
                                className={cn(
                                  " w-full border border-idesign-navy-20 p-2 align-middle",
                                  {
                                    "bg-idesign-navy-10":
                                      topItem.parent_id === 0 ||
                                      child.parent_id === 0,
                                  },
                                )}
                              >
                                {relationshipData.item_mappings.find(
                                  (mapping: any) =>
                                    (mapping.from_listitem_id === child.id ||
                                      mapping.from_listitem_id ===
                                        topItem.id) &&
                                    (mapping.to_listitem_id === child.id ||
                                      mapping.to_listitem_id === topItem.id),
                                ) ? (
                                  <CheckIcon className="w-full" size={24} />
                                ) : null}
                              </TableCell>
                              {topItem.items.map(
                                (
                                  otherChildren: any,
                                  otherChildIndex: number,
                                ) => (
                                  <TableCell
                                    key={`top-other-child-cell-${otherChildren.id}-${otherChildIndex}`}
                                    className={cn(
                                      " w-full border border-idesign-navy-20 p-2 align-middle",
                                      {
                                        "bg-idesign-navy-10":
                                          otherChildren.parent_id === 0,
                                      },
                                    )}
                                  >
                                    {relationshipData.item_mappings.find(
                                      (mapping: any) =>
                                        (mapping.from_listitem_id ===
                                          child.id ||
                                          mapping.from_listitem_id ===
                                            otherChildren.id) &&
                                        (mapping.to_listitem_id === child.id ||
                                          mapping.to_listitem_id ===
                                            otherChildren.id),
                                    ) ? (
                                      <CheckIcon className="w-full" size={24} />
                                    ) : null}
                                  </TableCell>
                                ),
                              )}
                            </React.Fragment>
                          );
                        },
                      )}
                    </TableRow>
                  ))}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};
