import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import "~/components/Reports/ReportStyles.css";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { API_PATHS } from "~/api/ApiPaths";
import AlignLoadingIndicator from "~/components/AlignLoadingIndicator";
import { List, ProgramData } from "~/components/Reports/ReportModels";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";

import AlignmentGrid from "~/components/Reports/AlignmentGrid";
import CourseActivities from "~/components/Reports/CourseActivities";
import CourseModules from "~/components/Reports/CourseModules";
import { ListToList } from "~/components/Reports/ListToList/ListToList";
import ProgramActivities from "~/components/Reports/ProgramActivities";
import ToteSummary from "~/components/Reports/ToteSummary";
import { SelectOption } from "~/components/ui/select";
import useAxios from "~/hooks/useAxios";
import useLogSessionActivity from "~/hooks/useLogSessionActivity";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";

const ReportsStyles = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: start;
  margin: 1rem;
  overflow: hidden;
  div {
    width: 100%;
  }

  main {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const Reports: React.FC = observer(() => {
  const [programData, setProgramData] = React.useState<ProgramData | null>(
    null,
  );
  const [hasProgramData, setHasProgramData] = React.useState<boolean>(false);
  const { data, requestUrl, authToken, error, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const { getLoggedIn, getAppUser, getAuthToken, setDropdownListReports } =
    useStore();
  const [appUser] = React.useState(getAppUser());
  const {
    ProgramLabel,
    PartnerLabel,
    CourseLabel,
    ModulesLabel,
    ActivitiesLabel,
  } = useOverrides();
  const programLabel = ProgramLabel();
  const partnerLabel = PartnerLabel();
  const courseLabel = CourseLabel();
  const activitiesLabel = ActivitiesLabel();
  const modulesLabel = ModulesLabel();
  const needToFetch = React.useRef<boolean>(true);
  const readyToFetch = React.useRef<boolean>(false);
  const displayReady = React.useRef<boolean>(false);
  const location = useLocation();
  const { logContentView } = useLogSessionActivity();
  const currentUrl = window.location.href;

  const tabNames = useMemo(
    () => [
      "Time on Task Summary",
      "Alignment Grid",
      "List to List",
      `${programLabel} ${activitiesLabel}`, // Program Activities
      `${courseLabel} ${activitiesLabel}`, // Course Activities
      `${courseLabel} ${modulesLabel}`, // Course Modules
      "Printable Report",
    ],
    [programLabel, activitiesLabel, courseLabel, modulesLabel],
  );

  //context is set and ready to fetch
  useEffect(() => {
    if (
      appUser.selected_program_id !== 0 &&
      appUser.selected_partner_id !== 0
    ) {
      readyToFetch.current = true;
    } else if (appUser.selected_program_id === 0) {
      readyToFetch.current = false;
    }
  }, [appUser.selected_program_id, appUser.selected_partner_id]);

  // triggered when context changes and need to reload report data
  useEffect(() => {
    if (appUser.selected_program_id !== 0 && !needToFetch.current) {
      needToFetch.current = true;
      displayReady.current = false;
      setProgramData(null);
    }
  }, [appUser.selected_program_id]);

  useEffect(() => {
    if (
      data?.entity.id === appUser.selected_program_id &&
      !programData &&
      !needToFetch.current
    ) {
      const programDetails: ProgramData = data.entity;
      setProgramData(programDetails);
      setHasProgramData(programDetails?.courses?.length > 0);
      setIsLoading(false);
      logContentView(location.pathname, tabNames[selectedTab]);

      const dropDownItems: SelectOption[] = [];
      programDetails?.mapped_lists.forEach((item: List) => {
        dropDownItems.push({
          value: `${item.id}`,
          label: item.name,
        });
      });
      setDropdownListReports(dropDownItems);
      displayReady.current = true;
    } else if (error) {
      setIsLoading(false);
      console.log(error);
    } else if (needToFetch.current && readyToFetch.current) {
      setIsLoading(true);
      needToFetch.current = false;
      requestUrl.current = `${API_PATHS.REPORTS_GET_PROGRAMDETAILS}/${appUser.selected_program_id}`; // This will have to come from the current context
      authToken.current = getAuthToken();
      fetch();
    }
  }, [
    programData,
    authToken,
    data,
    error,
    fetch,
    getAuthToken,
    requestUrl,
    getLoggedIn,
    appUser.selected_program_id,
    logContentView,
    tabNames,
    location.pathname,
    selectedTab,
    setDropdownListReports,
  ]);

  type CustomTabTriggerProps = {
    value: string;
  };

  const CustomTabTrigger = ({ value }: CustomTabTriggerProps) => (
    <TabsTrigger
      value={value}
      className="rounded-md rounded-b-none data-[state=active]:shadow-none data-[state=active]:text-align-blue data-[state=active]:border data-[state=active]:border-b-0"
      onClick={() => {
        setSelectedTab(tabNames.indexOf(value));
        logContentView(location.pathname, value);
      }}
    >
      <span className="text-sm">{value}</span>
    </TabsTrigger>
  );

  return (
    <ReportsStyles>
      <title>Reports - Align App</title>
      {appUser.selected_program_id === 0 ? (
        <p style={{ padding: 0 }}>
          {" "}
          Please select a {partnerLabel} and {programLabel} to edit.
        </p>
      ) : isLoading ? (
        <AlignLoadingIndicator />
      ) : hasProgramData && displayReady.current ? (
        <main role="main">
          <Tabs
            defaultValue={tabNames[0]}
            className="w-full h-full overflow-hidden flex flex-col"
          >
            <TabsList className="grid grid-cols-auto-fit-150 h-fit !p-0 top-0.5 relative">
              <CustomTabTrigger value={tabNames[0]} />
              <CustomTabTrigger value={tabNames[1]} />
              {!currentUrl.includes("app") && (
                <CustomTabTrigger value={tabNames[2]} />
              )}
              <CustomTabTrigger value={tabNames[3]} />
              <CustomTabTrigger value={tabNames[4]} />
              <CustomTabTrigger value={tabNames[5]} />
              {/* <CustomTabTrigger value={tabNames[6]} /> */}
            </TabsList>

            <div className="overflow-y-scroll border rounded-md rounded-tl-none w-full h-full">
              <TabsContent value="Time on Task Summary">
                <ToteSummary programData={programData} />
              </TabsContent>
              <TabsContent value="Alignment Grid">
                <AlignmentGrid
                  programData={programData}
                  targetList={null}
                  forPrint={false}
                />
              </TabsContent>
              <TabsContent className="mt-0" value="List to List">
                <ListToList programData={programData} />
              </TabsContent>
              <TabsContent value="Program Activities">
                <ProgramActivities programData={programData} forPrint={false} />
              </TabsContent>
              <TabsContent value="Course Activities">
                <CourseActivities programData={programData} forPrint={false} />
              </TabsContent>
              <TabsContent value="Course Modules">
                <CourseModules programData={programData} forPrint={false} />
              </TabsContent>
              {/* <TabsContent value="Printable Report">
                <PrintReport programData={programData} />
              </TabsContent> */}
            </div>
          </Tabs>
        </main>
      ) : (
        <div>No {programLabel} data</div>
      )}
    </ReportsStyles>
  );
});

export default Reports;
