import React, { Fragment, useMemo } from "react";

import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { EditIcon } from "lucide-react";

import { DeleteListItem } from "~/components/lists/ListEditor/ListItemTable/DeleteListItem";
import { DraggableRow } from "~/components/lists/ListEditor/ListItemTable/DraggableRow";
import ListItemDialog from "~/components/lists/ListEditor/ListItemTable/ListItemDialog";
import {
  CheckboxCell,
  InputCell,
  MappingRestrictionsCell,
  RowDragHandleCell,
} from "~/components/lists/ListEditor/ListItemTable/ListItemTableCells";
import { Checkbox } from "~/components/ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Button } from "~/components/ui-rework/button";
import { useListEditorContext } from "~/context/ListEditorProvider";
import { cn } from "~/lib/utils";
import { getTmpId, useStore } from "~/models/AlignEditor";
import { IListManagerListItem, ListItemEditable } from "~/models/ListManager";

type ListItemTableProps = {
  isEditing: boolean;
};

const ListItemTable = ({ isEditing }: ListItemTableProps) => {
  const { listItems, setListItems, setDeletedListItems, setIsListReordered } =
    useListEditorContext();

  const columns = useMemo<ColumnDef<IListManagerListItem>[]>(
    () => [
      {
        id: "drag-handle",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      },
      {
        id: "edit",
        cell: ({ row }) => (
          <div className="flex">
            <ListItemDialog
              item={row.original}
              onSubmit={(data) => handleEditItem(data, row.original)}
            >
              <button title="Edit Activity" aria-label="Edit Activity">
                <EditIcon size="16px" className="text-ocean-120" />
              </button>
            </ListItemDialog>
          </div>
        ),
      },
      {
        id: "code",
        accessorKey: "code",
        header: "Code",
        cell:
          isEditing && 1 < 0
            ? InputCell
            : ({ row }) => (
                <div
                  className={cn({
                    "font-bold": row.original.is_heading,
                  })}
                >
                  {row.original.code}
                </div>
              ),
      },
      {
        id: "name",
        accessorKey: "name",
        header: "Name",
        cell:
          isEditing && 1 < 0
            ? InputCell
            : ({ row }) => (
                <div className={cn({ "font-bold": row.original.is_heading })}>
                  {row.original.name}
                </div>
              ),
      },
      {
        id: "display_name",
        accessorKey: "display_name",
        header: "Display Name",
        cell:
          isEditing && 1 < 0
            ? InputCell
            : ({ row }) => (
                <div className={cn({ "font-bold": row.original.is_heading })}>
                  {row.original.display_name}
                </div>
              ),
      },
      {
        id: "mapping_restrictions",
        accessorKey: "mapping_restrictions",
        header: "Mapping Restrictions",
        cell:
          isEditing && 1 < 0
            ? MappingRestrictionsCell
            : ({ row }) => {
                return (
                  <div>
                    {row.original.mapping_restrictions?.join(", ") ||
                      "Unrestricted"}
                  </div>
                );
              },
      },
      {
        id: "is_heading",
        accessorKey: "is_heading",
        header: "List Header",
        cell:
          isEditing && 1 < 0
            ? CheckboxCell
            : ({ row }) => {
                return (
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={`${row.original.id}-list-header-checkbox`}
                      checked={row.original.is_heading}
                      aria-disabled
                      disabled
                    />
                    <label
                      htmlFor={`${row.original.id}-list-header-checkbox`}
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {row.original.is_heading ? "Yes" : "No"}
                    </label>
                  </div>
                );
              },
      },
      {
        id: "is_mappable",
        accessorKey: "is_mappable",
        header: "Mappable",
        cell:
          isEditing && 1 < 0
            ? CheckboxCell
            : ({ row }) => {
                return (
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={`${row.original.id}-mappable-checkbox`}
                      checked={row.original.is_mappable}
                      aria-disabled
                      disabled
                    />
                    <label
                      htmlFor={`${row.original.id}-mappable-checkbox`}
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {row.original.is_mappable ? "Yes" : "No"}
                    </label>
                  </div>
                );
              },
      },
      {
        id: "delete",
        cell: ({ row }) => (
          <DeleteListItem listItem={row.original} onDelete={handleDeleteItem} />
        ),
      },
    ],
    [isEditing], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { listManagerInstance } = useStore();

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => listItems?.map(({ id }) => id),
    [listItems],
  );

  const columnVisibility = {
    "drag-handle": isEditing,
    edit: isEditing,
    delete: isEditing,
  };

  const table = useReactTable({
    data: listItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id as unknown as string, //required because row indexes will change
    state: {
      columnVisibility,
    },
  });

  const handleEditItem = (
    listItemUpdates: ListItemEditable,
    listItemOriginal: IListManagerListItem,
  ) => {
    const listItem: IListManagerListItem = {
      ...listItemOriginal,
      ...listItemUpdates,
      isChanged: true,
    };
    setListItems((prev) =>
      prev.map((item) => (item.id === listItem.id ? listItem : item)),
    );
  };

  const handleAddItem = (listItem: ListItemEditable) => {
    const newListItem: IListManagerListItem = {
      id: getTmpId(),
      created_at: +new Date(),
      list_id: listManagerInstance().getCurrentSnapshot()[0].id,
      code: listItem.code,
      name: listItem.name,
      display_name: listItem.display_name,
      parent_id: 0,
      is_heading: listItem.is_heading,
      is_mappable: listItem.is_mappable,
      mapping_restrictions: listItem.mapping_restrictions,
      delete: false,
      isNewItem: true,
    };
    setListItems((prev) => [...prev, newListItem]);
  };

  const handleDeleteItem = (item: IListManagerListItem) => {
    setListItems((data) => data.filter((i) => i.id !== item.id));
    if (!item.isNewItem)
      setDeletedListItems((data) => [...data, { ...item, delete: true }]);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setListItems((data) => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);
        if (oldIndex !== newIndex) setIsListReordered(true);

        return arrayMove(data, oldIndex, newIndex); //this is just a splice util
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <div className="overflow-auto">
      {isEditing ? (
        <>
          <div className="bg-gradient-1 font-semibold flex gap-2 items-center w-full p-2 border border-idesign-navy-120 rounded-t-md">
            <span className="text-white">List Items</span>
            <ListItemDialog onSubmit={handleAddItem}>
              <Button
                variant="outline"
                size="sm"
                testid="lists-add-item"
                className="dark"
              >
                Add List Item
              </Button>
            </ListItemDialog>
          </div>
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            // onDragStart={handleDragStart}
            sensors={sensors}
          >
            <div className="overflow-y-auto rounded-b-md border border-t-0 border-idesign-navy-120 mb-4">
              <Table className="table-fixed overflow-hidden border-collapse border-spacing-0">
                <colgroup>
                  <col style={{ width: "36px" }} />
                  <col style={{ width: "40px" }} />
                  <col style={{ width: "180px", textAlign: "right" }} />
                  <col style={{ width: "350px" }} />
                  <col style={{ minWidth: "350px" }} />
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "100px" }} />
                  <col style={{ width: "88px" }} />
                  <col style={{ width: "40px" }} />
                </colgroup>
                <TableHeader className="rounded-md bg-gradient-1">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow
                      key={headerGroup.id}
                      className={cn(
                        "hover:bg-transparent",
                        "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                      )}
                    >
                      {headerGroup.headers.map((header, idx, headers) => {
                        if (idx < 2 || idx === headers.length - 1) {
                          // Skip the first two headers and the last header
                          return null;
                        }

                        const colSpan =
                          idx === 2 ? 3 : idx === headers.length - 2 ? 2 : 1;

                        return (
                          <TableHead
                            className={cn(
                              "text-white font-bold border-x border-idesign-navy-120 p-2 py-4 ",
                              "last:border-r-0 first:border-l-0",
                              idx === 2 && "pl-[100px]",
                            )}
                            key={header.id}
                            colSpan={colSpan}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  <SortableContext
                    items={dataIds}
                    strategy={verticalListSortingStrategy}
                  >
                    {table.getRowModel().rows.map((row) => (
                      <DraggableRow key={row.id} row={row} />
                    ))}
                  </SortableContext>
                </TableBody>
              </Table>
            </div>
          </DndContext>
        </>
      ) : (
        <div className="overflow-y-auto rounded-md border border-idesign-navy-120 mb-4">
          <Table className="table-fixed overflow-hidden border-collapse border-spacing-0">
            <colgroup>
              <col style={{ width: "100px" }} />
              <col style={{ width: "350px" }} />
              <col style={{ minWidth: "350px" }} />
              <col style={{ width: "230px" }} />
              <col style={{ width: "100px" }} />
              <col style={{ width: "88px" }} />
            </colgroup>
            <TableHeader className="bg-gradient-1">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="hover:bg-transparent">
                  {headerGroup.headers.map((header, index, headers) => {
                    return (
                      <TableHead
                        className={cn(
                          "text-white font-bold p-2 py-4 bg-transparent border-x border-idesign-navy-120",
                          "last:border-r-0 first:border-l-0",
                        )}
                        key={header.id}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => {
                  return (
                    <Fragment key={row.id}>
                      <TableRow
                        key={row.id + "_original"}
                        className={cn(
                          "hover:!bg-ocean-10 bg-cerulean-10 border-none border-idesign-navy-120",
                          "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                          row.original.is_heading && "!bg-white ",
                        )}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className="py-2 border border-b-0 border-idesign-navy-120"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default ListItemTable;
