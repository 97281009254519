import React, { useEffect, useState } from "react";
import { ScrollArea } from "~/components/ui-rework/scroll-area";

import { IListManagerList } from "~/models/ListManager";

type FilterTooltipProps = {
  filterTopList: number[];
  filterLeftList: number[];
  listLeft: IListManagerList | null;
  listTop: IListManagerList | null;
};

const FilterTooltip = ({
  filterLeftList,
  filterTopList,
  listLeft,
  listTop,
}: FilterTooltipProps) => {
  const [leftListTags, setLeftListTags] = useState<string[]>([]);
  const [topListTags, setTopListTags] = useState<string[]>([]);

  useEffect(() => {
    if (listLeft !== null && filterLeftList.length > 0) {
      const tags = filterLeftList
        .map((listId) => {
          return (
            listLeft.items?.find((item: any) => item.id === listId)?.name ?? ""
          );
        })
        .filter(Boolean);
      setLeftListTags(tags);
    } else {
      setLeftListTags([]);
    }
  }, [filterLeftList, listLeft]);

  useEffect(() => {
    if (listTop !== null && filterTopList.length > 0) {
      const tags = filterTopList
        .map((listId) => {
          return (
            listTop.items?.find((item: any) => item.id === listId)?.name ?? ""
          );
        })
        .filter(Boolean);
      setTopListTags(tags);
    } else {
      setTopListTags([]);
    }
  }, [filterTopList, listTop]);

  return (
    <ScrollArea className="light border-none m-0 !shadow-none p-4 pr-0 bg-gradient-3 backdrop-blur-[4px] rounded-md border border-ocean-70 ">
      <div className="gap-2 flex flex-col items-start w-fit max-h-[250px] pr-10">
        <span className="font-bold text-xs text-idesign-navy-70">
          FILTERS APPLIED
        </span>
        <span className="font-bold text-base">List Parents</span>
        {filterLeftList.length > 0 && (
          <>
            <span className="font-bold text-sm">{listLeft?.name}</span>
            {leftListTags.map((tag, index: number) => (
              <span key={index} className="font-normal text-sm ml-4">
                {tag}
              </span>
            ))}
          </>
        )}
        {filterTopList.length > 0 && (
          <>
            <span className="font-bold text-sm">{listTop?.name}</span>
            {topListTags.map((tag, index: number) => (
              <span key={index} className="font-normal text-sm ml-4">
                {tag}
              </span>
            ))}
          </>
        )}
      </div>
    </ScrollArea>
  );
};

export default FilterTooltip;
