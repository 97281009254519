import React from "react";

import { ScrollArea } from "~/components/ui-rework/scroll-area";

type ListFilterTooltipProps = {
  filterFormValues?: any;
};

const ListFilterTooltip = ({ filterFormValues }: ListFilterTooltipProps) => {
  const category_global = filterFormValues?.category_global;
  const category_partner = filterFormValues?.category_partner;
  const categoryTags = [];
  if (!category_global || !category_partner) {
    categoryTags.push(category_global ? "Global" : "Local");
  }

  const is_active_yes = filterFormValues?.is_active_yes;
  const is_active_no = filterFormValues?.is_active_no;
  const activeTags = [];
  if (!is_active_yes || !is_active_no) {
    activeTags.push(is_active_yes ? "Yes" : "No");
  }

  const listTypeTags = filterFormValues?.list_type || [];
  const programTags = filterFormValues?.programs || [];

  return (
    <ScrollArea className="light border-none m-0 !shadow-none p-4 pr-0 bg-gradient-3 backdrop-blur-[4px] rounded-md border border-ocean-70 ">
      <div className="gap-2 flex flex-col items-start w-fit max-h-[250px] pr-10">
        <span className="font-bold text-xs text-idesign-navy-70">
          FILTERS APPLIED
        </span>
        {categoryTags.length > 0 && (
          <>
            <span className="font-bold text-sm">Category</span>
            {categoryTags.map((tag, index: number) => (
              <span key={index} className="font-normal text-sm ml-4">
                {tag}
              </span>
            ))}
          </>
        )}
        {activeTags.length > 0 && (
          <>
            <span className="font-bold text-sm">Active</span>
            {activeTags.map((tag, index: number) => (
              <span key={index} className="font-normal text-sm ml-4">
                {tag}
              </span>
            ))}
          </>
        )}
        {listTypeTags.length > 0 && (
          <>
            <span className="font-bold text-sm">List Type</span>
            {listTypeTags.map((tag: any, index: number) => (
              <span key={index} className="font-normal text-sm ml-4">
                {tag}
              </span>
            ))}
          </>
        )}
        {programTags.length > 0 && (
          <>
            <span className="font-bold text-sm">Program</span>
            {programTags.map((tag: any, index: number) => (
              <span key={index} className="font-normal text-sm ml-4">
                {tag}
              </span>
            ))}
          </>
        )}
      </div>
    </ScrollArea>
  );
};

export default ListFilterTooltip;
