import React, { useEffect, useState } from "react";

import * as SelectPrimitive from "@radix-ui/react-select";
import { cva, type VariantProps } from "class-variance-authority";
import { Check, ChevronDown, ChevronUp } from "lucide-react";

import { FormControl } from "~/components/ui/form";
import { cn } from "~/lib/utils";

const SelectRoot = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const selectVariants = cva(
  cn(
    "flex items-center w-full justify-between rounded-md border border-input px-3 py-2 ring-offset-select-ring",
    "data-[placeholder]:text-muted-foreground",
    "text-foreground",
    "hover:border-ring",
    "focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-4",
    "focus:shadow focus:shadow-spread-1.5 focus:shadow-[rgb(0, 18, 26)]",
    "data-[state=open]:outline-none data-[state=open]:ring-1 data-[state=open]:ring-ring data-[state=open]:ring-offset-4",
    "disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:border-input",
    "[&>span]:truncate",
    "text-sm",
  ),
  {
    variants: {
      variant: {
        default: "p-2.5 rounded-[10px] text-base",
        shadow: "shadow-md",
        drawer: "bg-white relative !opacity-100",
        small: "h-9 w-auto rounded-[17px] bg-transparent text-start",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

type SelectTriggerProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.Trigger
> &
  VariantProps<typeof selectVariants> & {
    testid?: string;
    noIcon?: boolean;
  };

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(({ className, children, variant, noIcon, testid = null, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(selectVariants({ variant }), className)}
    {...props}
    data-testid={testid}
  >
    {children}
    {noIcon ? null : (
      <SelectPrimitive.Icon asChild>
        <ChevronDown className="ml-1" />
      </SelectPrimitive.Icon>
    )}
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    {...props}
  >
    <ChevronUp className="h-4 w-4" />
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    {...props}
  >
    <ChevronDown className="h-4 w-4" />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "dark relative z-[1001] max-h-96 min-w-[8rem] overflow-hidden rounded-md border border-ocean-70 bg-popover-gradient backdrop-blur-[1px] text-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        position === "popper" &&
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
        className,
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          "p-2",
          position === "popper" &&
            "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn("py-1.5 pl-8 pr-2 text-sm font-semibold", className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none hover:outline-1 hover:outline-white focus:outline-white focus:outline-1 focus:bg-idesign-navy-90 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className,
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <Check className="h-4 w-4" />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 my-1 h-px bg-ocean-70", className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export type SelectOption = {
  label: string;
  value: string;
};

type SelectProps = {
  disabled?: boolean;
  displayError?: boolean;
  fieldError?: string;
  fieldName: string;
  key?: number;
  options: { label: string; value: string }[];
  placeholder?: string;
  testid?: string;
  value?: string;
  handleChange: (fieldName: string, newValue: string) => void;
};

const Select = ({
  fieldName,
  options,
  handleChange,
  value,
  disabled,
  placeholder,
  fieldError = "",
  displayError,
  variant,
  testid,
}: SelectProps & VariantProps<typeof selectVariants>) => {
  const [defaultValue, setDefaultValue] = useState(value);

  useEffect(() => {
    if (defaultValue !== value) {
      setDefaultValue(value);
    }
  }, [value, defaultValue]);

  return (
    <>
      <SelectRoot
        value={defaultValue}
        defaultValue={defaultValue}
        onValueChange={(newValue) => {
          handleChange(fieldName, newValue);
        }}
        disabled={disabled}
        data-testid={testid}
      >
        <SelectTrigger variant={variant} testid={testid}>
          <SelectValue placeholder={placeholder ? placeholder : "Select..."} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {options.map((option, index) => (
              <SelectItem value={option.value} key={index}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </SelectRoot>
      {displayError && fieldError ? (
        <div className="flex m-2.5 items-center">
          <div
            className="material-icons icon-left icon-list-item error"
            aria-hidden="true"
          >
            error
          </div>
          <div className="mb-0 pl-1 text-muted-foreground text-sm">
            {fieldError}
          </div>
        </div>
      ) : (
        displayError && <div className="h-[15px] mt-2.5"></div>
      )}
    </>
  );
};

export type FormSelectProps = {
  disabled?: boolean;
  error?: string;
  options: { label: string; value: string }[];
  placeholder?: string;
  testid?: string;
  value?: string | null;
  handleChange: (...event: any[]) => void;
} & VariantProps<typeof selectVariants>;

const FormSelect = ({
  disabled,
  error,
  options,
  placeholder,
  testid,
  value,
  variant,
  handleChange,
}: FormSelectProps) => {
  const defaultValue = value === null ? undefined : value;
  return (
    <SelectRoot
      onValueChange={handleChange}
      defaultValue={defaultValue}
      disabled={disabled}
      data-testid={testid}
    >
      <FormControl>
        <SelectTrigger
          variant={variant}
          testid={testid}
          className={cn({
            "border-error-foreground hover:border-error-foreground forcus:border-error-foreground":
              error,
          })}
        >
          <SelectValue placeholder={placeholder ? placeholder : "Select..."} />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <SelectGroup>
          {options.map((option, index) => (
            <SelectItem value={option.value} key={index}>
              {option.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </SelectRoot>
  );
};

export {
  FormSelect,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectRoot,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
};
