import React, { useEffect, useState, useRef } from "react";

import { TabsContent } from "@radix-ui/react-tabs";
import { ArrowLeft, FilePenLine, Sparkles } from "lucide-react";
import { observer } from "mobx-react-lite";

import { API_PATHS, IData } from "~/api/ApiPaths";
import AlignLoadingIndicator from "~/components/AlignLoadingIndicator";
import { AutosaveIndicator } from "~/components/program/mapping/AutosaveIndicator";
import { MappingAssistantParentFilter } from "~/components/program/mapping/filters/MappingAssistantParentFilter";
import { MappingManualParentFilter } from "~/components/program/mapping/filters/MappingManualParentFilter";
import { MappingSearch } from "~/components/program/mapping/filters/MappingSearch";
import { useAvailableListsAPI } from "~/components/program/mapping/hooks/useAvailableListsAPI";
import { MappingAssistant } from "~/components/program/mapping/MappingAssistant";
import { MappingSelectionTable } from "~/components/program/mapping/MappingSelectionTable";
import { getParentItemOptions } from "~/components/program/mapping/utils/getMappingParentItemOptions";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { Button } from "~/components/ui-rework/button";
import { Select } from "~/components/ui-rework/select";
import {
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "~/components/ui-rework/sheet";
import { useMappingSheetContext } from "~/context/MappingSheetProvider";
import useAxiosTwo from "~/hooks/useAxios";
import EmptyStateGraphic from "~/images/Edit-Mappings-Empty-State.svg";
import { NodeTypes, useStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

const MappingListLoader = observer(() => {
  const menuDataFetched = useRef(false);
  const { getAuthToken } = useRootStore();
  const {
    initDrawerList,
    drawerListsReady,
    resetDrawerListReady,
    setCurrentMappingListId,
    getCurrentMappingListId,
    getDrawerListWithSelectionsTree,
    getProgram,
  } = useStore();
  const listLoaded = useRef(false);
  const listInitialized = useRef(false);

  const { availableLists, error } = useAvailableListsAPI();

  const {
    data: listData,
    error: listError,
    fetch: listFetch,
    requestUrl: listRequestUrl,
    authToken: listAuthToken,
    resetFetchedData,
  } = useAxiosTwo({ method: "GET", initialValue: null });
  const {
    selectedList,
    setSelectedList,
    setParentItemId,
    listItemHierarchy,
    setListItemHierarchy,
    parentItemOptions,
    setParentItemOptions,
    nodeId,
    nodeType,
  } = useMappingSheetContext();

  /** Using these values to track the tab state without managing it programmatically.
   *  This lets us preserve the A11y features from the underlying Radix tabs components. */
  const [tabKey] = useState(+new Date());
  const [currentTab, setCurrentTab] = useState<"manual" | "assistant">(
    "manual",
  );

  const nodeDisplayName = getProgram()?.getCurriculumNodeById(
    nodeType,
    nodeId ?? -1,
    true,
  )?.display_name;

  useEffect(() => {
    if (selectedList?.value !== undefined && !menuDataFetched.current) {
      menuDataFetched.current = true;
      setCurrentMappingListId(parseInt(selectedList.value));
      listLoaded.current = true;
    }
  }, [selectedList, setCurrentMappingListId]);

  useEffect(() => {
    if (listLoaded.current) {
      listLoaded.current = false;
      listRequestUrl.current = `${
        API_PATHS.LIST_BY_ID
      }/${getCurrentMappingListId()}`;
      listAuthToken.current = getAuthToken();
      listFetch();
    }
  }, [
    getCurrentMappingListId,
    listFetch,
    getAuthToken,
    listRequestUrl,
    listAuthToken,
  ]);

  useEffect(() => {
    if (listData && listInitialized.current === false) {
      listInitialized.current = true;
      const Data = listData as IData;
      if (Data.issuccess) {
        initDrawerList(Data.entity);
        resetFetchedData();
      }
    }
  }, [listData, initDrawerList, resetFetchedData]);

  // useListDetailsTreeQuery();

  const handleChange = (_fieldName: string, newValue: string) => {
    menuDataFetched.current = false;
    listInitialized.current = false;
    resetDrawerListReady();

    setSelectedList(availableLists.find((list) => list.value === newValue));
  };

  const [listSelectKey, setListSelectKey] = useState(+new Date());
  useEffect(() => {
    if (selectedList?.value === undefined) setListSelectKey(+new Date());
  }, [selectedList]);

  useEffect(() => {
    if (drawerListsReady) {
      const items = getDrawerListWithSelectionsTree();
      setListItemHierarchy(items);
      setParentItemOptions(getParentItemOptions(items));
    }
  }, [
    drawerListsReady,
    getDrawerListWithSelectionsTree,
    setListItemHierarchy,
    setParentItemOptions,
  ]);

  const showMappingTable =
    getCurrentMappingListId() !== -1 &&
    listError === "" &&
    drawerListsReady &&
    listItemHierarchy &&
    parentItemOptions;

  const noCurrentSelectedList = getCurrentMappingListId() === -1;

  return (
    <div className="grid h-full gap-2" style={{ gridTemplateRows: "40px 1fr" }}>
      <div>
        {showMappingTable && (
          <Button
            variant="outline"
            className="px-4 text-base"
            onClick={() => {
              resetDrawerListReady();
              setCurrentMappingListId(-1);
              setSelectedList(undefined);
              setParentItemId(undefined);
              setCurrentTab("manual");
              setListItemHierarchy(undefined);
              setParentItemOptions(undefined);
            }}
          >
            <div className="flex justify-center items-center gap-2">
              <ArrowLeft />
              <span>Select new list</span>
            </div>
          </Button>
        )}
      </div>
      <div className="flex flex-col h-full min-h-0">
        <SheetHeader className="space-y-4">
          <div className="absolute right-[68px] top-[17px]">
            <AutosaveIndicator />
          </div>
          <SheetTitle>Edit Mappings</SheetTitle>
          <SheetDescription className="text-base text-foreground">
            <DescriptionContent
              nodeDisplayName={nodeDisplayName}
              drawerListsReady={drawerListsReady}
            />
          </SheetDescription>
          {noCurrentSelectedList && (
            <div>
              {error === "" && availableLists.length !== 0 && (
                <Select
                  key={listSelectKey}
                  fieldName="list-selector"
                  placeholder="Select a list..."
                  options={availableLists}
                  value={selectedList?.value}
                  handleChange={handleChange}
                  testid="program-mappings-select"
                />
              )}
              {error !== "" && <span>{JSON.stringify(error)}</span>}
            </div>
          )}
        </SheetHeader>

        {drawerListsReady === false && (
          <div className="flex flex-col flex-1 pt-4 overflow-auto">
            <div className="flex flex-1 flex-col items-center">
              {getCurrentMappingListId() === -1 ? (
                <div className="flex flex-row gap-9 px-4 pt-24 items-center justify-center">
                  <img alt="Edit Mappings" src={EmptyStateGraphic} />
                  <div className="flex flex-col gap-2 justify-center">
                    <h3 className="text-lg font-semibold">Select List</h3>
                    <p className="text-base max-w-96">
                      Select a list to begin mapping items to your curriculum.
                      By mapping list items to curriculum, you can see alignment
                      to learning outcomes, skills, and more.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col mt-[100px] h-full">
                  <AlignLoadingIndicator />
                </div>
              )}
            </div>
          </div>
        )}
        {showMappingTable &&
          (nodeType === NodeTypes.Activity ? (
            <Tabs
              key={tabKey}
              className="flex flex-1 flex-col overflow-auto"
              defaultValue="manual"
              onValueChange={(value) => {
                setCurrentTab(value as "manual" | "assistant");
              }}
            >
              <div className="flex items-center gap-4 py-6">
                {currentTab === "manual" ? (
                  <>
                    <MappingManualParentFilter />
                    <MappingSearch />
                  </>
                ) : (
                  <MappingAssistantParentFilter />
                )}

                <div className="ml-auto">
                  <TabsList
                    style={{
                      boxShadow: "0px 2px 4px 0px rgba(0, 18, 26, 0.50)",
                    }}
                    className="rounded-full grid grid-cols-2 h-fit p-0 bg-transparent border border-idesign-navy-120"
                  >
                    <TabsTrigger
                      value="manual"
                      className="rounded-full h-9 px-4 text-idesign-navy-100 data-[state=active]:text-white data-[state=active]:bg-idesign-navy-100"
                    >
                      <FilePenLine />
                    </TabsTrigger>
                    <TabsTrigger
                      value="assistant"
                      className="rounded-full h-9 px-4 text-idesign-navy-100 data-[state=active]:text-white data-[state=active]:bg-idesign-navy-100"
                    >
                      <Sparkles />
                      {/* <CustomSparkles /> */}
                    </TabsTrigger>
                  </TabsList>
                </div>
              </div>
              <TabsContent
                className="flex flex-1 flex-col overflow-auto"
                value="manual"
              >
                <MappingSelectionTable />
              </TabsContent>
              <TabsContent
                className="flex flex-1 flex-col overflow-auto"
                value="assistant"
              >
                <MappingAssistant />
              </TabsContent>
            </Tabs>
          ) : (
            <>
              <div className="flex items-center gap-4 py-6">
                <MappingManualParentFilter />
                <MappingSearch />
              </div>
              <MappingSelectionTable />
            </>
          ))}
      </div>
    </div>
  );
});

type DescriptionContentProps = {
  nodeDisplayName?: string;
  drawerListsReady: boolean;
};

const DescriptionContent = ({ nodeDisplayName }: DescriptionContentProps) => {
  const { nodeType, selectedList } = useMappingSheetContext();

  return (
    <span
      className="grid gap-x-4 gap-y-2"
      style={{ gridTemplateColumns: "auto 1fr" }}
    >
      <span className="font-bold">{nodeType}</span>
      <span>{nodeDisplayName}</span>
      {selectedList ? (
        <>
          <span className="font-bold">List</span>
          <span>{selectedList.label}</span>
        </>
      ) : null}
    </span>
  );
};

export default MappingListLoader;
