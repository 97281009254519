import React, { Fragment, useCallback, useMemo, useState } from "react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { observer } from "mobx-react-lite";

import { DeleteMappingDialog } from "~/components/program/dialogs/DeleteMappingDialog";
import MappingSheet from "~/components/program/mapping/MappingSheet";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import { Button } from "~/components/ui-rework/button";
import { cn } from "~/lib/utils";
import { useStore, NodeTypes, IMappingListItem } from "~/models/AlignEditor";

type MappingTableProps = {
  className?: string;
  nodeId: number;
  nodeType: NodeTypes;
};

const MappingTable = observer(
  ({ className, nodeId, nodeType }: MappingTableProps) => {
    const columns = useMemo<ColumnDef<IMappingListItem>[]>(
      () => [
        {
          id: "item_name",
          header: "Item Name",
          cell: ({ row }) => <div>{row.original.listitem_display_name}</div>,
        },
        {
          id: "mappings_list_name",
          header: "Mappings List Name",
          cell: ({ row }) => <div>{row.original.list_name}</div>,
        },
        {
          id: "delete",
          header: "",
          cell: ({ row }) => (
            <DeleteMappingDialog
              mapping={row.original}
              onDelete={deleteMapping}
            />
          ),
        },
      ],
      [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const { getProgram, deleteCurriculumNodeMapping } = useStore();

    const program = getProgram();

    const getMappings = useCallback(() => {
      return program ? program.getCurriculumNodeMappings(nodeType, nodeId) : [];
    }, [nodeType, nodeId, program]);

    const [data, setData] = useState<IMappingListItem[]>(getMappings());

    const updateMappingsDisplay = useCallback(() => {
      setData(getMappings());
    }, [setData, getMappings]);

    const deleteMapping = (mappingId: number) => {
      deleteCurriculumNodeMapping(nodeId, nodeType, mappingId);
      updateMappingsDisplay();
    };

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });

    return (
      <>
        <div className="bg-gradient-1 text-white font-semibold flex gap-2 items-center w-full px-3 py-2 border-t border-l border-idesign-navy-120">
          <span className="text-base">{`${nodeType} Mappings`}</span>
          <MappingSheet
            nodeId={nodeId}
            nodeType={nodeType}
            updateMappingsDisplay={updateMappingsDisplay}
          >
            <Button
              className="dark text-sm py-1 px-2 font-normal"
              variant="outline"
              testid="program-edit-mappings"
            >
              Edit Mappings
            </Button>
          </MappingSheet>
        </div>
        {data.length > 0 && (
          <Table
            className={cn(
              "table-fixed border-t border-l border-idesign-navy-120",
              className,
            )}
          >
            <colgroup>
              <col />
              <col />
              <col style={{ width: "33px" }} />
            </colgroup>
            <TableHeader className="bg-gradient-1">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="hover:bg-transparent">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        className={cn(
                          "text-white font-bold border-x border-idesign-navy-120 p-2",
                          "last:border-r-0 first:border-l-0",
                        )}
                        key={header.id}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow
                      key={row.id + "_original"}
                      className={cn("hover:bg-gray-200 border-b-0", {
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      })}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className="p-2 border-x border-idesign-navy-120 last:border-r-0 first:border-l-0"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </>
    );
  },
);

export default MappingTable;
