import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "~/components/ui/form";
import { SelectMultiple } from "~/components/ui/select-multiple";
import { SheetFooter } from "~/components/ui/sheet";
import { Button } from "~/components/ui-rework/button";
import { Label } from "~/components/ui-rework/label";
import { Separator } from "~/components/ui-rework/separator";
import { SheetContent } from "~/components/ui-rework/sheet";
import { IListManagerList } from "~/models/ListManager";

import { FilterAutosaveIndicator } from "./FilterAutoSaveIndicator";

type FilterSheetProps = {
  setFilterTopList: React.Dispatch<React.SetStateAction<number[]>>;
  setFilterLeftList: React.Dispatch<React.SetStateAction<number[]>>;
  listLeft: IListManagerList | null;
  listTop: IListManagerList | null;
};

export const FilterSheet = ({
  setFilterTopList,
  setFilterLeftList,
  listLeft,
  listTop,
}: FilterSheetProps) => {
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const init = useRef(true);

  const topOptions = listTop?.items?.map((item) => ({
    label: item.display_name,
    value: item.display_name,
  }));

  const leftOptions = listLeft?.items?.map((item) => ({
    label: item.display_name,
    value: item.display_name,
  }));

  const formSchema = z.object({
    topListFilters: z.array(z.string()).optional(),
    leftListFilters: z.array(z.string()).optional(),
  });

  const defaultValues = {
    topListFilters: [],
    leftListFilters: [],
  };

  const form = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const { control } = form;
  const formWatch = useWatch({ control });

  useEffect(() => {
    if (formWatch.topListFilters) {
      setLastSaved(new Date());
      setFilterTopList(
        formWatch.topListFilters
          .map((filterName: string) => {
            const item = listTop?.items?.find(
              (topItem: any) => topItem.display_name === filterName,
            );
            return item ? item.id : null;
          })
          .filter((id): id is number => id !== null),
      );
    }
    if (formWatch.leftListFilters) {
      setLastSaved(new Date());
      setFilterLeftList(
        formWatch.leftListFilters
          .map((filterName: string) => {
            const item = listLeft?.items?.find(
              (leftItem: any) => leftItem.display_name === filterName,
            );
            return item ? item.id : null;
          })
          .filter((id): id is number => id !== null),
      );
    }
    if (
      formWatch.leftListFilters?.length === 0 &&
      formWatch.topListFilters?.length === 0
    ) {
      init.current = true;
      setLastSaved(null);
    }
  }, [formWatch, setFilterLeftList, setFilterTopList]);

  return (
    <SheetContent side="left" className="w-1/3">
      <Form {...form}>
        <div className="h-full">
          <div className="flex flex-col justify-between h-full">
            <div className="px-14">
              <h1 className="text-[32px] font-semibold mt-[72px] mb-9">
                Filter
              </h1>
              <Separator />
              <h2 className="text-2xl font-semibold mt-9 mb-5">List Parents</h2>
              <Label>List Parent for {listTop?.name}</Label>
              <FormField
                control={form.control}
                name={"topListFilters"}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <SelectMultiple
                        clearable
                        maxHeight="75px"
                        options={
                          topOptions?.map((option) => ({
                            label: option.label ?? "",
                            value: option.value ?? "",
                          })) ?? []
                        }
                        buttonMaxHeight="75px"
                        testid="top_list_filter"
                        values={field.value ?? undefined}
                        onValuesChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Label className="mt-5">List Parent for {listLeft?.name}</Label>
              <FormField
                control={form.control}
                name={"leftListFilters"}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <SelectMultiple
                        clearable
                        maxHeight="75px"
                        options={
                          leftOptions?.map((option) => ({
                            label: option.label ?? "",
                            value: option.value ?? "",
                          })) ?? []
                        }
                        buttonMaxHeight="75px"
                        testid="left_list_filter"
                        values={field.value ?? undefined}
                        onValuesChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <SheetFooter className="mb-14 mr-4">
              <Button
                type="reset"
                variant="ghost"
                onClick={() => {
                  setFilterTopList([]);
                  setFilterLeftList([]);
                  form.reset(defaultValues);
                  init.current = true;
                }}
              >
                Reset
              </Button>
            </SheetFooter>
          </div>
        </div>
        <div className="absolute right-[68px] top-[17px]">
          <FilterAutosaveIndicator
            lastSaved={lastSaved}
            setLastSaved={setLastSaved}
            init={init}
          />
        </div>
      </Form>
    </SheetContent>
  );
};
